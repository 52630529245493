import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { Layout, PageContainer, SEO } from "components";
import { Link } from "gatsby";
import { routes } from "utils";

const NotFoundPage = ({ location }) => {
  const title = "Page not found";

  return (
    <Layout clearNav={false} location={location}>
      <SEO image={pageImage} title={title} uri={location.pathname} />
      <PageContainer>
        <Row>
          <StyledCol
            xs={12}
            md={8}
            lg={6}
            style={{ height: "400px", marginTop: "10%" }}
          >
            <Title>Oops, sorry...</Title>
            <p>
              We couldn't find the page you were looking for. This is either
              because:
            </p>
            <ul>
              <li>
                There is an error in the URL entered into your web browser.
                Please check the URL and try again.
              </li>
              <li>The page you are looking for has been moved or deleted.</li>
            </ul>
            <p>
              You can return to our homepage by clicking{" "}
              <Link to={routes("home")}>here</Link>.
              {/* , or you can try searching for the content you are seeking by
              clicking <Link to={routes('home')}>here</Link>. */}
            </p>
          </StyledCol>
        </Row>
      </PageContainer>
    </Layout>
  );
};

export default NotFoundPage;

const StyledCol = styled(Col)`
  margin-bottom: 17px;
`;

const Title = styled.h2`
  font-family: "Ringside Narrow";
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-bottom: 1rem;
`;
